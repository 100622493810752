import { gql } from '@apollo/client';
import { isEmpty, uniq } from 'lodash-es';
import { queryGraphQL } from '../../../../data';
import type { QueryModifierGroupsReadArgs } from '../../../../types/schema';

export const getModifierGroupsIds = async ( lineItem, modifiers ) => {
	let metadata = {};
	if ( !isEmpty( lineItem.modifications?.elements ) ) {
		metadata = lineItem.modifications.elements.reduce( ( obj, modification ) => {
			if ( !obj[ modification.modifier.id ] ) {
				obj = { ...obj, [ modification.modifier.id ]: 1 };
			} else {
				obj = { ...obj, [ modification.modifier.id ]: obj[ modification.modifier.id ] + 1 };
			}
			return obj;
		}, {} );
		if ( !isEmpty( metadata ) ) {
			metadata = Object.keys( metadata ).reduce( ( obj, modifierId ) => {
				if ( metadata[ modifierId ] ) {
					obj = {
						...obj,
						[ modifierId ]: metadata[ modifierId ] === 1
							? metadata[ modifierId ]
							: metadata[ modifierId ] / ( ( lineItem.unitQty ?? 1000 ) / 1000 ),
					};
				}
				return obj;
			}, {} );
			
		}
		
		if ( !isEmpty( modifiers ) ) {
			const modifierGroupsExternalIds: string[] = modifiers.reduce( ( arr, modifier ) => {
				if ( metadata?.[ modifier.id ] ) {
					arr = [ ...arr, modifier.modifierGroup.id ];
				}
				return arr;
			}, [] );
			
			const data = await queryGraphQL<QueryModifierGroupsReadArgs>( {
				query    : gql`
					query ModifierGroupsRead_4733($options: FilterOptions) {
						modifierGroupsRead(options: $options) { items { externalId, id } }
					}`,
				variables: { options: { filter: { externalId: { $in: uniq( modifierGroupsExternalIds ) } } } },
			} );
			return { modifierGroupsIds: data.modifierGroupsRead?.items?.map( ( { id } ) => id ), metadata };
		} else {
			return { modifierGroupsIds: [], metadata };
		}
	} else {
		return { modifierGroupsIds: [], metadata };
	}
	
};

export const getLineItemsToCreate = ( lineItemChunk, taxes, taxPercent, defaultTax ) => lineItemChunk.map( ( item ) => {
	const rate = Math.round( ( item.orderTax ? taxPercent : item.tax || 0 ) * 100000 );
	const tax = taxes.elements.find( ( tax ) => tax.name === 'Tax (auto)' ) || defaultTax;
	const cloverTaxes = item.prices?.filter( ( tax ) => tax.metadata?.externalTax && tax.metadata?.useTax );
	
	return {
		item         : item.item?.externalId && { id: item.item.externalId },
		name         : item.name,
		alternateName: `${item.id}:${item.name?.slice( 0, 10 ) || ''}`,
		price        : Math.round( item.price * 100 ),
		unitQty      : Math.round( item.quantity * 1000 ),
		unitName     : item.unit,
		note         : item.description,
		itemCode     : item.code,
		isRevenue    : item.isRevenue,
		binName      : item.binName && !item.binName?.includes( ':' )
			? `${item.binName}`
			: ( item.category?.externalId || item.category?.id ) && `${item.category.name}:${item.category?.externalId || item.category?.id}`,
		
		taxRates: !isEmpty( cloverTaxes )
			? cloverTaxes.map( ( tax ) => ( {
				id       : tax.metadata?.externalId,
				name     : tax.name,
				rate     : tax.isPercent ? Math.round( tax.value * 100000 ) : 0,
				taxAmount: !tax.isPercent ? Math.round( tax.value * 100 ) * item.quantity : 0,
			} ) )
			: rate ? [ {
				id  : tax.id,
				name: tax.name,
				rate: rate,
			} ] : null,
		// taxRates:  item.tax ?? taxPercent ? [ rate ? {
		// 	id  : tax.id,
		// 	name: tax.name,
		// 	rate: rate,
		// } : undefined, ...!isEmpty( cloverTaxes ) ? cloverTaxes.map( ( tax ) => ( { id: tax.id, name: tax.name, rate: Math.round( tax.value ) * 100000 } ) ) : [] ].filter( Boolean )
		// 	: null,
	};
} );
